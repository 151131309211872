// Stylesheets
import './main.scss';
import './components/**/*.scss';

// import favicon here.
import './resources/images/favicon.png';
import './accountUpdate';

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
  }
}

(() => {
  const getFirstParentForm = (
    element: HTMLElement
  ): HTMLFormElement | HTMLElement => {
    return element.tagName === 'FORM' || element.tagName === 'HTML'
      ? element
      : getFirstParentForm(element.parentElement!);
  };

  // removes classes that hide errors on submit
  const hideErrorOnSubmit = () => {
    const allFormInputs = document.querySelectorAll(
      '.cmp-form-text, .cmp-form-options'
    );

    document.querySelectorAll('button[type="submit"]').forEach(button => {
      button.addEventListener(
        'click',
        () => {
          allFormInputs.forEach(el => {
            el.classList.remove('hide-error');
          });
        },
        false
      );
    });
  };

  // adds classes that hide/show errors on input change & keyup
  const handleFormKeyupEvents = () => {
    const allFormInputs = document.querySelectorAll(
      '.cmp-form-text, .cmp-form-options'
    );

    allFormInputs.forEach(el => {
      el.querySelector('input')?.addEventListener('keyup', () => {
        if (el.classList.contains('has-error')) {
          el.classList.remove('hide-error');
        } else {
          el.classList.add('hide-error');
        }
      });
      el.querySelector('select, input')?.addEventListener('change', () => {
        if (el.classList.contains('has-error')) {
          el.classList.remove('hide-error');
        } else {
          el.classList.add('hide-error');
        }
      });
    });
  };

  // switches between the forms when links are clicked
  const handleFormSwitch = () => {
    const allFormInputs = document.querySelectorAll(
      '.cmp-form-text, .cmp-form-options'
    );

    window.Bus?.on('emu-button:click', e => {
      if (!e || !e.title) {
        return;
      }

      switch (e.title) {
        case 'container-select:click':
          document
            .querySelector('.container-select__button-container')
            ?.classList.add('container-select__hidden');

          const formContainer = document.getElementById(`${e.id}-container`);
          if (formContainer) {
            formContainer.classList.add('container-select__active');
            formContainer.scrollIntoView({
              behavior: 'smooth',
            });
          }

          break;
        case 'container-select:cancel':
          const cancelButton = document.getElementById(e.id);
          if (!cancelButton) {
            console.error('Cancel Button needs a unique ID');
            return;
          }

          const form = getFirstParentForm(cancelButton);
          if ((form as HTMLFormElement).reset) {
            (form as HTMLFormElement).reset();
          }

          document
            .querySelectorAll('.container-select__container')
            .forEach(el => el.classList.remove('container-select__active'));

          const buttonContainer = document.querySelector(
            '.container-select__button-container'
          );
          if (buttonContainer) {
            buttonContainer.classList.remove('container-select__hidden');
            buttonContainer.scrollIntoView({
              behavior: 'smooth',
            });
          }

          // clears errors
          allFormInputs.forEach(el => {
            el.classList.add('hide-error');
          });
          break;
        default:
          break;
      }
    });
  };

  // handles click on link to scroll to particular section
  const handleISIScrollToDiv = () => {
    const anchors = document.querySelectorAll('.isi-links a[href^="#"]');
    anchors.forEach(function (anchor) {
      anchor.addEventListener('click', function (event) {
        event.preventDefault();
        const target = anchor?.getAttribute('href');
        if (target) {
          const section = document.querySelector(
            '.cmp-isi-container > .aaaem-isi-container__isi-content ' + target
          ) as HTMLElement;

          const sectionOffsetTop =
            section.getBoundingClientRect().top + window.scrollY - 86;
          if (sectionOffsetTop) {
            window.scrollTo({
              top: sectionOffsetTop,
              behavior: 'smooth',
            });
          }
        }
      });
    });
  };

  // handles show hide input field according to option selection
  const handleToggleField = () => {
    const formFields = document.querySelectorAll('.form-content__select');
    formFields.forEach(function (formField) {
      if (formField) {
        formField.addEventListener('click', function (event) {
          const checkedRadio = formField.querySelector(
            'input[type="radio"]:checked'
          ) as HTMLInputElement;
          const selectedOption = checkedRadio?.value;
          const textField = formField?.parentNode as HTMLElement;
          var hiddenTextField;
          if (textField) {
            hiddenTextField = textField.nextElementSibling?.querySelector(
              '.form-content__field'
            );
          }
          if (selectedOption === 'yes') {
            hiddenTextField?.classList.remove('form-content__field--hide');
          } else {
            hiddenTextField?.classList.add('form-content__field--hide');
          }
        });
      }
    });
  };

  const handleNumberOnlyFields = () => {
    const numberOnlyFields = document.querySelectorAll('.number-only');
    numberOnlyFields.forEach(field => {
      const inp = field.querySelector('input') as HTMLInputElement;
      inp?.addEventListener('input', e => {
        inp.value = inp.value.replace(/[^0-9\ ]/gi, '');
      });
    });
  };

  const handleAlphabetOnlyFields = () => {
    const numberOnlyFields = document.querySelectorAll('.alphabet-only');
    numberOnlyFields.forEach(field => {
      const inp = field.querySelector('input') as HTMLInputElement;
      inp?.addEventListener('input', e => {
        inp.value = inp.value.replace(/[^a-zA-Z\ ]/gi, '');
      });
    });
  };

  const init = () => {
    hideErrorOnSubmit();
    handleFormKeyupEvents();
    handleFormSwitch();
    handleISIScrollToDiv();
    handleToggleField();
    handleNumberOnlyFields();
    handleAlphabetOnlyFields();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
