const _temp0 = require("./components/footer/_footer.scss");

const _temp1 = require("./components/header/_header.scss");
const _temp2 = require("./components/header/_nav.scss");

const _temp3 = require("./components/isi/_isi.scss");

const _temp4 = require("./components/teaser/_teaser.scss");

module.exports = {
  "footer":   {
    "_footer": _temp0
  },
  "header":   {
    "_header": _temp1,
    "_nav": _temp2
  },
  "isi":   {
    "_isi": _temp3
  },
  "teaser":   {
    "_teaser": _temp4
  }
}