const shippinLocationContainerID = '#newShippingLocation-container';
const medicalDirectorContainerID = '#medicalDirectorChange-container';
const contactInformationContainerID = '#contactInformationUpdate-container';
const accountReactivationContainerID = '#accountReactivation-container';
const addressChangeContainerID = '#addressChange-container';
const addressRadioName = 'Shipping__c';
const businessOwnershipContainerID = '#businessNameOrOwnershipChange-container';
const buisinessRadioName = 'Business_Name_Change__c';
const billingInputID = '#Billing_Account_Number__c';
const shippingInputID = '#Shipping_Account_Number__c';
const payerAccountInputID = '#Payer_Account_Number__c';
const accountInputID = '#Account_Numbers_s__c';
const subjectID = '#subject';
document.addEventListener('DOMContentLoaded', function () {
  //common funtion for shipping location and medical director forms
  const updateSubjectWithInputChange = (
    activeconatinerID,
    inputID,
    subjectID,
    subjectLine
  ) => {
    const activeContainer = document.querySelector(activeconatinerID);
    const formInput = getInputEl(activeContainer, inputID);
    const subjectInput = getInputEl(activeContainer, subjectID);

    formInput.addEventListener('input', () => {
      const inputNumber = formInput?.value.trim();
      subjectInput.value = `${subjectLine} - ${inputNumber}`;
    });
  };

  //common function for contact information and account reactivation
  const updateSubjectWithMultipleInputChange = (
    activeconatinerID,
    input1ID,
    input2ID,
    subjectID,
    subjectLine
  ) => {
    const activeContainer = document.querySelector(activeconatinerID);
    const formInput1 = getInputEl(activeContainer, input1ID);
    const formInput2 = getInputEl(activeContainer, input2ID);

    const subjectInput = getInputEl(activeContainer, subjectID);

    formInput1.addEventListener('input', () => {
      addMultipleInputSubjectLine(
        formInput1,
        formInput2,
        subjectInput,
        subjectLine
      );
    });
    formInput2.addEventListener('input', () => {
      addMultipleInputSubjectLine(
        formInput1,
        formInput2,
        subjectInput,
        subjectLine
      );
    });
  };

  const addMultipleInputSubjectLine = (
    formInput1,
    formInput2,
    subjectInput,
    subjectLine
  ) => {
    const formInput1Number = formInput1?.value.trim();
    const formInput2Number = formInput2?.value.trim();
    subjectInput.value = `${subjectLine} - ${formInput1Number} & ${formInput2Number}`;
  };

  //common function for address change and business ownership
  const updateSubjectWithRadioInputChange = (
    containerId,
    inputSelectors,
    radioName,
    subjectId,
    updateSubjectCallback
  ) => {
    const activeContainer = document.querySelector(containerId);
    if (!activeContainer) return;

    const inputs = inputSelectors.map(selector =>
      getInputEl(activeContainer, selector)
    );
    const radios = activeContainer.querySelectorAll(
      `input[type="radio"][name="${radioName}"]`
    );
    const subjectInput = getInputEl(activeContainer, subjectId);

    radios.forEach(radio => {
      radio.addEventListener('change', () =>
        updateSubjectCallback(inputs, subjectInput)
      );
    });

    inputs.forEach(input => {
      input.addEventListener('input', () =>
        updateSubjectCallback(inputs, subjectInput)
      );
    });
  };

  const addAddressSubject = (inputs, subjectInput) => {
    const [shippingInput, billingInput] = inputs;
    const checkedRadio = document.querySelector(
      'input[name="Shipping__c"]:checked'
    ) as HTMLInputElement;
    const selectedOption = checkedRadio?.value;
    const selectedOptionText = checkedRadio.nextElementSibling
      ? checkedRadio.nextElementSibling.textContent
      : '';
    const shippingNumber = shippingInput?.value.trim();
    const billingNumber = billingInput?.value.trim();
    if (selectedOption === 'Shipping Change' && shippingNumber) {
      subjectInput.value = `${selectedOptionText} - ${shippingNumber}`;
    } else if (selectedOption === 'Billing Change' && billingNumber) {
      subjectInput.value = `${selectedOptionText} - ${billingNumber}`;
    } else if (selectedOption === 'Both') {
      subjectInput.value = `${selectedOptionText} - ${shippingNumber} & ${billingNumber}`;
    } else {
      subjectInput.value = '';
    }
  };

  const addOwnershipSubject = (inputs, subjectInput) => {
    const [billingInput] = inputs;
    const checkedRadio = document.querySelector(
      'input[name="Business_Name_Change__c"]:checked'
    ) as HTMLInputElement;
    const selectedOptionText = checkedRadio.nextElementSibling
      ? checkedRadio.nextElementSibling.textContent
      : '';
    const billingNumber = billingInput?.value.trim();

    subjectInput.value = `${selectedOptionText} - ${billingNumber}`;
  };

  //function to get element of perticular id
  const getInputEl = (activeContainer, id) => {
    const inputValue = activeContainer?.querySelector(id) as HTMLInputElement;
    return inputValue;
  };

  if (document.body.id === 'account-update-request') {
    // form address change form
    updateSubjectWithRadioInputChange(
      addressChangeContainerID,
      [shippingInputID, billingInputID],
      addressRadioName,
      subjectID,
      addAddressSubject
    );

    //shipping location change form
    updateSubjectWithInputChange(
      shippinLocationContainerID,
      billingInputID,
      subjectID,
      'Add an Additional Shipping Location'
    );

    //medical director change
    updateSubjectWithInputChange(
      medicalDirectorContainerID,
      shippingInputID,
      subjectID,
      'Medical Director Change'
    );

    //business ownership change
    updateSubjectWithRadioInputChange(
      businessOwnershipContainerID,
      [billingInputID],
      buisinessRadioName,
      subjectID,
      addOwnershipSubject
    );

    //contact information change
    updateSubjectWithMultipleInputChange(
      contactInformationContainerID,
      accountInputID,
      payerAccountInputID,
      subjectID,
      'Contact Information Update'
    );

    //account reactivation change
    updateSubjectWithMultipleInputChange(
      accountReactivationContainerID,
      billingInputID,
      shippingInputID,
      subjectID,
      'Account Reactivation'
    );
  }
});
